import React, { useState, useEffect, useRef } from 'react';
import DropDown from '../UI/DropDown';
import ExcelExportBtn from '../UI/ExcelExportBtn';
import StaffCsvTemplate from '../../CSVTemplates/NewStaff';
import { CREATE_BULK_USERS } from '../../api/urls';
import useApi from '../../api/useApi';
import { read, utils } from 'xlsx';

function UploadCSV({ handleClose, institutes, staffRole, refreshList }) {
  const [selectedInstitution, selectInstitution] = useState();
  const [csv, setCSV] = useState();
  const [csvArray, setCsvArray] = useState();

  const csvRef = useRef();

  const [uploadRes, uploadData] = useApi();

  function ExcelDateToJSDate(serial) {
    var utc_days = Math.floor(serial - 25569);
    var utc_value = utc_days * 86400;
    var date_info = new Date(utc_value * 1000);

    var fractional_day = serial - Math.floor(serial) + 0.0000001;

    var total_seconds = Math.floor(86400 * fractional_day);

    var seconds = total_seconds % 60;

    total_seconds -= seconds;

    var hours = Math.floor(total_seconds / (60 * 60));
    var minutes = Math.floor(total_seconds / 60) % 60;

    return new Date(date_info.getFullYear(), date_info.getMonth(), date_info.getDate(), hours, minutes, seconds);
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!selectedInstitution) {
      alert('Select Institute');
      return;
    }

    let isNoEmail = false;

    let body = [];
    if (csvArray.length > 0) {
      csvArray.every((x) => {
        if (!x.Email) {
          alert("Some Staff member's email is not added. Please check and upload");
          isNoEmail = true;
          return false;
        }
        if (!x.PERMANENT_Address_State) {
          alert("Some Staff member's state is not added. Please check and upload");
          isNoEmail = true;
          return false;
        }

        let stu = {
          full_name: x.Name || '',
          email_id: x.Email || '',
          phone: x.Contact || '',
          gender: x.Gender || '',
          maritalStatus: x.MaritalStatus || '',
          dateOfBirth: x?.DateOfBirth ? new Date(ExcelDateToJSDate(x?.DateOfBirth)) : '',
          // aadhar: x.Aadhar || '',
          roleType: staffRole === 'teaching' ? 'te' : 'nt',
          role: staffRole,
          address: {
            hno: x.PERMANENT_Address_Hno || '',
            society: x.PERMANENT_Address_Society || '',
            district: x.PERMANENT_Address_District || '',
            city: x.PERMANENT_Address_City || '',
            state: x.PERMANENT_Address_State
              ? x.PERMANENT_Address_State.toLowerCase() === 'j&k'
                ? 'Jammu and Kashmir'
                : x.PERMANENT_Address_State
              : '',
            pincode: x.PERMANENT_Address_Pincode || '',
          },
          caddress: {
            hno: x.Correspondance_Address_Hno || '',
            society: x.Correspondance_Address_Society || '',
            district: x.Correspondance_Address_District || '',
            city: x.Correspondance_Address_City || '',
            state: x.Correspondance_Address_State || '',
            pincode: x.Correspondance_Address_Pincode || '',
          },
          education: {
            university: x.Education_University || '',
            degree: x.Education_Degree || '',
            fieldOfStudy: x.Education_FieldOfStudy || '',
            grade: x.Education_Grade || '',
            startDate: x.Education_StartDate ? new Date(ExcelDateToJSDate(x.Education_StartDate)) : '',
            endDate: x.Education_EndDate ? new Date(ExcelDateToJSDate(x.Education_EndDate)) : '',
          },
          jobProfile: {
            title: x.JobProfile_Title || '',
            supervisor: x.JobProfile_Supervisor || '',
            email: x.Email || '',
            contact: x.Contact || '',
            startDate: x.JobProfile_StartDate ? new Date(ExcelDateToJSDate(x.JobProfile_StartDate)) : '',
            salary: x.JobProfile_Salary || '',
          },
          bankDetail: {
            bank: x.BankName || '',
            accountNo: x.AccountNo || '',
            ifsc: x.ifsc || '',
          },
          experience: [
            {
              title: x.Experience_Title || '',
              type: x.Experience_Type || '',
              companyName: x.Experience_CompanyName || '',
              location: x.Experience_Location || '',
              startDate: x.Experience_StartDate ? new Date(ExcelDateToJSDate(x.Experience_StartDate)) : '',
              endDate: x.Experience_EndDate ? new Date(ExcelDateToJSDate(x.Experience_EndDate)) : '',
            },
          ],
        };
        body.push(stu);
        return true;
      });
    }

    if (isNoEmail) {
      return;
    }

    const staffData = {
      user: body,
    };

    uploadData(CREATE_BULK_USERS(selectedInstitution), 'POST', staffData);
  };

  useEffect(() => {
    if (!uploadRes.isLoading && !uploadRes.isError && uploadRes.status == 200) {
      alert('SUCCESS');
      handleClose();
      refreshList();
    }

    if (!uploadRes.isLoading && uploadRes.isError) {
      alert('Failed to upload');
    }
  }, [uploadRes]);

  useEffect(() => {
    if (csv) {
      readData();
    }
  }, [csv]);

  const readData = () => {
    const file = csv;
    // const reader = new FileReader();

    // reader.onload = function (e) {
    //   const text = e.target.result;
    //   processCSV(text);
    // };

    // reader.readAsText(file);
    const reader = new FileReader();
    reader.onload = (event) => {
      const wb = read(event.target.result);
      const sheets = wb.SheetNames;
      if (sheets.length) {
        const rows = utils.sheet_to_json(wb.Sheets[sheets[0]]);
        setCsvArray(rows);
        // console.log(rows);
      }
    };
    reader.readAsArrayBuffer(file);
  };

  // const processCSV = (str, delim = ',') => {
  //   const headers = str
  //     .slice(0, str.indexOf('\n'))
  //     .split(delim)
  //     .map((x) => x.replace('\r', ''));

  //   let rows = str.slice(str.indexOf('\n') + 1).split('\n');
  //   rows = rows.filter((x) => x.length > 3);

  //   const newArray = rows.map((row) => {
  //     const values = row.split(delim).map((x) => x.replace('\r', '').replace(/['"]+/g, ''));

  //     const eachObject = headers.reduce((obj, header, i) => {
  //       obj[header] = values[i];
  //       return obj;
  //     }, {});
  //     return eachObject;
  //   });
  //   console.log(newArray);
  //   setCsvArray(newArray);
  // };

  return (
    <div
      className="pb-10 w-full fixed overflow-auto left-0 bottom-0 top-0 z-30"
      style={{
        background: 'linear-gradient(rgba(0, 0, 0, 0.5),   rgba(0, 0, 0, 0.5))',
      }}
    >
      <div className="relative w-2/5 pb-6 mx-auto h-auto mt-6 bg-white rounded-2xl">
        <div className="flex justify-between items-center py-3  border-b border-borderYellow">
          <div className="font-bold px-8">
            <p>Upload Staff CSV</p>
          </div>
          <div className="flex px-8 gap-x-8 items-center">
            <img src="assets/img/Close.svg" className=" h-3 cursor-pointer" alt="" onClick={handleClose} />
          </div>
        </div>

        <div className="px-8 pt-6 float-right">
          <ExcelExportBtn
            data={[]}
            fileName={'staff_CSV.csv'}
            text={'Download CSV Template'}
            width={'w-1/3'}
            headers={StaffCsvTemplate}
          />
        </div>

        <form autoComplete="off" className="px-8" onSubmit={handleSubmit}>
          <div className="flex w-full">
            <div className="pb-4 w-full ">
              <div className="my-6 w-full relative font-semibold ">
                <label htmlFor="budget" className="text-textDark font-medium text-sm">
                  Select Institution<span className="text-red-500 ml-1">*</span>
                </label>
                <DropDown
                  width={'w-full'}
                  title={'Select'}
                  data={institutes}
                  field={'name'}
                  type={'obj'}
                  handleItemClick={(x) => {
                    selectInstitution(x._id);
                  }}
                  margin={'mt-2 mx-auto'}
                />
              </div>

              <div className="my-6 w-full relative font-semibold ">
                <label htmlFor="budget" className="text-textDark font-medium text-sm">
                  Staff CSV
                </label>
                <div className="relative flex">
                  <input
                    hidden
                    ref={csvRef}
                    type="file"
                    onChange={(e) => setCSV(e.target.files[0])}
                    className="w-full h-9 rounded-3xl text-xs border border-borderLight px-4 mt-2 py-2 focus:outline-none"
                  />
                  <input
                    type="text"
                    disabled
                    value={csv?.name}
                    className="w-full h-9 rounded-3xl text-xs border border-borderLight px-4 mt-2 py-2 focus:outline-none"
                  />
                  <button
                    className="px-10 py-2 rounded-3xl bg-primary text-xs text-white focus:outline-none flex justify-center text-center items-center h-9 mt-2 absolute right-0"
                    onClick={(e) => {
                      e.preventDefault();
                      csvRef.current.click();
                    }}
                  >
                    Upload
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="flex gap-x-4 justify-end">
            {uploadRes.isLoading ? (
              <img src="assets/img/loader.svg" className="w-14 m-auto mt-16" alt="" />
            ) : (
              <button
                className="w-40 border px-8 py-2 rounded-3xl text-xs text-white bg-primary border-primary focus:outline-none flex justify-center items-center"
                type="submit"
              >
                Save
              </button>
            )}
          </div>
        </form>
      </div>
    </div>
  );
}

export default UploadCSV;
