import React, { useEffect, useState } from 'react';
import { useStateValue } from '../ContextApi/StateProvider';
import {
  INSTITUTE_AI_MODEL,
  AI_MODEL_POST,
  AI_MODEL,
  INSTITUTE_AI_MODEL_GET,
  INSTITUTE_AI_MODEL_PUT,
} from '../api/urls';
import useApi from '../api/useApi';

const AIModel = () => {
  const [{ institutes }] = useStateValue();
  const [openAdd, setOpenAdd] = useState(false);
  const [assignModel, setAssignModel] = useState();
  const [selectedId, selectId] = useState();
  const [selectedInst, selectInst] = useState();

  const [modelsRes, getModels] = useApi();
  const [aiModelRes, createAIModel] = useApi();
  const [assignRes, assignAIModel] = useApi();
  const [instituteAiModelRes, instituteAIModel] = useApi();

  useEffect(() => {
    getModels(AI_MODEL, 'GET');
    instituteAIModel(INSTITUTE_AI_MODEL_GET, 'GET');
  }, []);

  const handleAddModel = () => {
    setOpenAdd(true);
  };

  const handleClose = () => {
    setOpenAdd(false);
  };

  const formDataModel = {
    model: '',
    service: '',
  };

  const [formData, setFormData] = useState(formDataModel);

  const handleInputChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value });

  const handleSubmit = (e) => {
    e.preventDefault();

    // if (selectedLeave) createModel(EDIT_LEAVE_TYPE(formData._id), 'PUT', formData);
    // else
    createAIModel(AI_MODEL_POST, 'POST', formData);
  };

  useEffect(() => {
    if (!aiModelRes.isLoading && !aiModelRes.isError && aiModelRes.status === 200) {
      alert('Success');
      getModels(AI_MODEL, 'GET');
      handleClose();
    }

    if (!aiModelRes.isLoading && aiModelRes.isError) {
      alert('Error');
    }
  }, [aiModelRes]);

  const handleAssignModel = (id) => {
    selectId(id);
    setAssignModel(id);
  };

  const handleAssignClose = () => {
    setAssignModel(null);
    selectId('');
    selectInst(null);
  };

  const handleSubmitAssign = (e) => {
    e.preventDefault();

    assignAIModel(INSTITUTE_AI_MODEL(selectedId), 'POST', formData);
  };
  const handleEditAssignModel = (e) => {
    e.preventDefault();

    assignAIModel(INSTITUTE_AI_MODEL_PUT(selectedInst._id), 'PUT', formData);
  };

  useEffect(() => {
    if (!assignRes.isLoading && !assignRes.isError && assignRes.status === 200) {
      alert('Success');
      instituteAIModel(INSTITUTE_AI_MODEL_GET, 'GET');
      handleAssignClose();
    }

    if (!assignRes.isLoading && assignRes.isError) {
      alert('Error');
    }
  }, [assignRes]);

  const checkIfModelAssigned = (id) => {
    if (Array.isArray(instituteAiModelRes?.data)) return instituteAiModelRes?.data?.find((x) => x.instituteId === id);
    else return null;
  };

  useEffect(() => {
    if (selectedInst) {
      setFormData({
        service: selectedInst.service,
        model: selectedInst.model,
      });
    }
  }, [selectedInst]);

  return (
    <div className="w-4/5 pb-10">
      {openAdd && (
        <div
          className="flex justify-center items-center h-full w-full fixed left-0 top-0 z-30 overflow-auto py-6"
          style={{ background: 'linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5))' }}
        >
          <div className="bg-white w-3/5 text-sm m-auto rounded-2xl relative mt-6">
            <div className="flex justify-between py-2 border-b border-borderYellow items-center">
              <span className="font-bold px-4 py-3 text-textBlue1">{'Add AI Model and Service'}</span>
              <div className="flex gap-x-8 items-center relative justify-end">
                <img src="assets/img/Close.svg" className="h-3 cursor-pointer mr-4" alt="" onClick={handleClose} />
              </div>
            </div>

            <div className=" w-full h-1/4 px-4 pb-6">
              <form className="grid grid-cols-2 gap-x-6 px-4 pb-2" onSubmit={handleSubmit}>
                <div className="my-2 relative font-semibold w-full">
                  <label htmlFor="budget" className="block text-parameter text-sm text-textDark">
                    Model
                  </label>
                  <input
                    type="text"
                    name="model"
                    // placeholder="All"
                    value={formData.model}
                    onChange={handleInputChange}
                    className="w-full text-xs rounded-3xl border border-borderLight text-textDark px-4 mt-2 py-2 focus:outline-none"
                  />
                </div>

                <div className="my-2 w-full relative font-semibold ">
                  <label htmlFor="budget" className="block text-parameter text-sm text-textDark">
                    Service
                  </label>
                  <input
                    type="text"
                    name="service"
                    // placeholder="slug"
                    value={formData.service}
                    onChange={handleInputChange}
                    className="w-full rounded-2xl text-xs border border-borderLight text-textDark px-4 mt-2 py-2 focus:outline-none"
                  />
                </div>

                <div className="w-full mt-4 flex justify-end col-span-2 gap-x-4">
                  {aiModelRes.isLoading ? (
                    <img src="assets/img/loader.svg" className="w-14 ml-auto" alt="" />
                  ) : (
                    <button
                      type="submit"
                      className="border px-12 py-2 text-xs rounded-3xl bg-primary border-primary font-medium text-white focus:outline-none flex justify-center text-center items-center"
                    >
                      {/* {selectedLeave ? 'Edit' : 'Create'} */}
                      Create
                    </button>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
      )}

      {(assignModel || selectedInst) && (
        <div
          className="flex justify-center items-center h-full w-full fixed left-0 top-0 z-30 overflow-auto py-6"
          style={{ background: 'linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5))' }}
        >
          <div className="bg-white w-3/5 text-sm m-auto rounded-2xl relative mt-6">
            <div className="flex justify-between py-2 border-b border-borderYellow items-center">
              <span className="font-bold px-4 py-3 text-textBlue1">{'Add AI Model and Service'}</span>
              <div className="flex gap-x-8 items-center relative justify-end">
                <img
                  src="assets/img/Close.svg"
                  className="h-3 cursor-pointer mr-4"
                  alt=""
                  onClick={handleAssignClose}
                />
              </div>
            </div>

            <div className=" w-full h-1/4 px-4 pb-6">
              <form
                className="grid grid-cols-2 gap-x-6 px-4 pb-2"
                onSubmit={selectedInst ? handleEditAssignModel : handleSubmitAssign}
              >
                <div className="my-2 relative font-semibold w-full">
                  <label htmlFor="budget" className="block text-parameter text-sm text-textDark">
                    Model
                  </label>
                  <select
                    name="model"
                    // placeholder="All"
                    value={formData.model}
                    onChange={handleInputChange}
                    className="w-full text-xs rounded-3xl border border-borderLight text-textDark px-4 mt-2 py-2 focus:outline-none"
                  >
                    <option value="">Select Model</option>
                    {modelsRes?.data?.model?.map((x) => (
                      <option key={x} value={x}>
                        {x}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="my-2 w-full relative font-semibold ">
                  <label htmlFor="budget" className="block text-parameter text-sm text-textDark">
                    Service
                  </label>
                  <select
                    type="text"
                    name="service"
                    // placeholder="slug"
                    value={formData.service}
                    onChange={handleInputChange}
                    className="w-full rounded-2xl text-xs border border-borderLight text-textDark px-4 mt-2 py-2 focus:outline-none"
                  >
                    <option value="">Select Service</option>
                    {modelsRes?.data?.service?.map((x) => (
                      <option key={x} value={x}>
                        {x}
                      </option>
                    ))}
                  </select>
                </div>

                {!selectedInst && (
                  <div className="my-2 relative font-semibold w-full">
                    <label htmlFor="budget" className="block text-parameter text-sm text-textDark">
                      Token Id
                    </label>
                    <input
                      type="text"
                      name="tokenId"
                      // placeholder="All"
                      value={formData.tokenId}
                      onChange={handleInputChange}
                      className="w-full text-xs rounded-3xl border border-borderLight text-textDark px-4 mt-2 py-2 focus:outline-none"
                    />
                  </div>
                )}

                <div className="w-full mt-4 flex justify-end col-span-2 gap-x-4">
                  {aiModelRes.isLoading ? (
                    <img src="assets/img/loader.svg" className="w-14 ml-auto" alt="" />
                  ) : (
                    <button
                      type="submit"
                      className="border px-12 py-2 text-xs rounded-3xl bg-primary border-primary font-medium text-white focus:outline-none flex justify-center text-center items-center"
                    >
                      {/* {checkIfModelAssigned(x._id) ? 'Edit' : 'Create'} */}
                      Assign
                    </button>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
      )}

      <div className="w-full bg-white rounded-xl h-auto mb-4">
        <div className="items-center px-6 py-3 border-b border-borderYellow">
          <div className="flex justify-between items-center">
            <div className="font-bold w-1/3">
              <p>AL Models</p>
            </div>
            <div className="flex gap-x-4 w-2/3 justify-end">
              <button
                className="w-32 border py-4 rounded-3xl text-xs focus:outline-none flex justify-center items-center h-7 bg-primary border-primary text-white"
                onClick={() => handleAddModel()}
              >
                Add New Model
              </button>
            </div>
          </div>
        </div>
        <div className="flex flex-wrap w-full h-1/4 px-6">
          <div className="w-full py-4">
            <div className="bg-white m-auto border rounded-2xl border-borderYellow">
              <div className="w-full flex bg-tableHeader rounded-3xl">
                <div className="flex w-full relative text-left px-4 text-xs font-medium">
                  <div className="w-2/6 border-r-2 py-2 px-2">Name</div>
                  <div className="w-1/6 border-r-2 py-2 px-2">Model</div>
                  <div className="w-2/6 border-r-2 py-2 px-2">Service</div>
                  <div className="w-1/6 py-2 px-2">Action</div>
                </div>
              </div>
              <div className="customScrollBar" style={{ maxHeight: '75vh' }}>
                {Array.isArray(institutes) &&
                  institutes.map((x, idx) => {
                    return (
                      <div key={x._id} className="w-full">
                        <div
                          className={`justify-center flex w-full relative text-left py-4 px-4 text-xs font-medium items-center ${
                            institutes.length - 1 !== idx && 'border-b border-borderYellow'
                          }`}
                        >
                          <div className="w-2/6 px-2 text-tableRowText ">{x?.name}</div>
                          <div className="w-1/6 px-2 text-tableRowText ">
                            {checkIfModelAssigned(x._id)?.model || '--'}
                          </div>
                          <div className="w-2/6 px-2 text-tableRowText ">
                            {checkIfModelAssigned(x._id)?.service || '--'}
                          </div>

                          <div className={`w-1/6 gap-x-3 flex`}>
                            <button
                              className={`px-3 border py-2 rounded-3xl text-xs focus:outline-none flex justify-center items-center  ${
                                !checkIfModelAssigned(x._id)
                                  ? 'bg-primary border-primary text-white'
                                  : 'bg-white border-primary text-primary'
                              }`}
                              onClick={() => {
                                if (checkIfModelAssigned(x._id)) selectInst(checkIfModelAssigned(x._id));
                                else handleAssignModel(x._id);
                              }}
                            >
                              {!checkIfModelAssigned(x._id) ? 'Add Model' : 'Edit Model'}
                            </button>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AIModel;
